import React, {useEffect, useState} from "react";
import {Menu, Dropdown, Avatar, Button, Modal} from "antd";
import { connect } from 'react-redux'
import {
  PieChartOutlined,
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import {Link} from "react-router-dom";
import IntlMessage from "../util-components/IntlMessage";
import axios from "axios";
import {userdataAtom} from "../../state_atoms";
import {useRecoilState} from "recoil";

const menuItem = [
    {
        title: "Account Details",
        icon: PieChartOutlined ,
        path: "/user/info"
    },
]

export default function NavProfile() {
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [userData, setUserData] = useRecoilState(userdataAtom);

    useEffect( () => {
        if (!userData.hasOwnProperty('patreon_advertisement')) {
            setModalVisible(userData['patreon_advertisement']);
        }
        console.log(userData);
    }, [userData]);

    async function doSignOut() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin'
        };
        await fetch('https://' + window.location.hostname + '/api/v2/user/logout', requestOptions);
        window.location.reload(false);
    }

    async function markModalAsSeen() {
        await axios.get('https://' + window.location.hostname + '/api/v2/user/patreon_ad_seen', {withCredentials: true})
            .then(r => {});
    }

    const handleOk = () => {
        markModalAsSeen().then(r => {});
        setModalVisible(false);
    };

    function profileMenu(user, color) {
        return (
            <>
                <div className="nav-profile nav-dropdown">
                    <div className="nav-profile-header">
                        <div className="d-flex">
                            {/*<Avatar size={45} src={profileImg}/>*/}
                            <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size={45}>
                                {user.display_name.substring(0,2)}
                            </Avatar>
                            <div className="pl-3">
                                <h4 className="mb-0">{user.display_name}</h4>
                                <span className="text-muted">
                                    Rank: Awesome
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="nav-profile-body">
                        <Menu style={{boxShadow: 'none'}}>
                            {menuItem.map((el, i) => {
                                return (
                                    <Menu.Item key={i}>
                                        <Link to={el.path}>
                                            <Icon className="mr-3" type={el.icon}/>
                                            <span className="font-weight-normal">{el.title}</span>
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                            <Menu.Item key={menuItem.length + 1} onClick={e => doSignOut()}>
                                <span>
                                  <LogoutOutlined className="mr-3"/>
                                  <span className="font-weight-normal"><IntlMessage id="title.sign_out"/></span>
                                </span>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
            </>
        );
    }

    function renderAuthenticatedBox () {
        const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
        const color = colorList[Math.floor(Math.random() * colorList.length)]
        if(userData !== false) {
            return(
                <Dropdown placement="bottomRight" overlay={profileMenu(userData, color)} trigger={["click"]}>
                    <Menu className="d-flex align-item-center" mode="horizontal">
                        <Menu.Item key={1}>
                            <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size={45}>
                                {userData.display_name.substring(0,2)}
                            </Avatar>
                        </Menu.Item>
                    </Menu>
                </Dropdown>
            );
        } else {
            return(
                <Link to="/auth/login" className="btn btn-primary">
                    <Button type="primary" size="small"><IntlMessage id="title.login"/></Button>
                </Link>
            );
        }
    }

    function renderPatreonBox () {
        return <></>
    }

    return (
        <>
            {renderPatreonBox()}
            {renderAuthenticatedBox()}
        </>
    );
}

//
// export default connect(null, {signOut})(NavProfile)
